import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`DB Snatch 12-12-12/arm`}</p>
    <p>{`DB Single Leg RDL’s 12-12-12/leg`}</p>
    <p>{`then,`}</p>
    <p>{`9/arm-KB Snatch (53/35)`}</p>
    <p>{`18-T2B’s`}</p>
    <p>{`7/arm-KB Snatch`}</p>
    <p>{`14-T2B’s`}</p>
    <p>{`5/arm-KB Snatch`}</p>
    <p>{`10-T2B’s`}</p>
    <p>{`For time.`}</p>
    <p>{`*`}{`Bonus WOD`}{`*`}</p>
    <p>{`21-15-9 reps each of:`}</p>
    <p>{`OH Walking Lunges/Leg (45/25`}{`#`}{`)`}</p>
    <p>{`Box Jump Overs (24/20″)`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our Annual Christmas party will be Saturday, December 10th. More
info to come soon!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      